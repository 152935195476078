
import { defineComponent, PropType } from 'vue'
import { TravelPass } from '@/models/TravelPass'
import Modal from '@/components/Modal.vue'
import Spinner from '@/components/Spinner.vue'
import { useStops } from '@/composables/useStops'

export default defineComponent({
  name: 'TravelPassStopsModal',
  emits: ['closeModal'],
  components: {
    Modal,
    Spinner
  },
  props: {
    travelPass: {
      type: Object as PropType<TravelPass>,
      required: true
    }
  },
  setup () {
    const { stops } = useStops()
    const stopNameFor = (stopId: number): string =>
      stops.value?.find(stop => stop.BusStopId === stopId)?.BusStopName || ''

    return {
      stops,
      stopNameFor
    }
  }
})
