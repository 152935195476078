<template>
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.44936 2.26355V14.1263L10.9017 18.7367V6.87394L2.44936 2.26355ZM4.64061 1.75014L12.0109 5.77029C12.2518 5.90172 12.4017 6.15425 12.4017 6.42871V13.8216H15.4256V1.75014H4.64061ZM12.4017 20.0001C12.4017 20.5694 11.7924 20.9312 11.2926 20.6586L1.34022 15.23C1.09928 15.0986 0.949361 14.846 0.949361 14.5716V1.01405C0.948069 0.947683 0.955589 0.880672 0.972441 0.814782C0.973937 0.808898 0.975503 0.803042 0.977137 0.797215C0.992157 0.744026 1.01333 0.691623 1.04094 0.641003C1.05427 0.616567 1.06876 0.593168 1.0843 0.570837C1.14538 0.4835 1.22458 0.409774 1.31645 0.355118C1.40554 0.302344 1.50465 0.268574 1.60705 0.255788C1.61035 0.255361 1.61367 0.254976 1.617 0.254613C1.6493 0.251016 1.6819 0.249505 1.7146 0.250142H16.1756C16.5898 0.250142 16.9256 0.585928 16.9256 1.00014V14.5716C16.9256 14.9858 16.5898 15.3216 16.1756 15.3216H12.4017V20.0001Z"
      fill="#C2002F"
    />
  </svg>
</template>
