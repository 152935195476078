
import { formatDate } from '@/helpers'
import { computed, defineComponent, PropType, toRef, ref } from 'vue'
import PeopleIcon from '@/components/vectors/PeopleIcon.vue'
import { TravelAccountJourneySet } from '@/models/TravelAccountJourney'
import useBookAgain from '@/composables/useBookAgain'
import Spinner from '@/components/Spinner.vue'
import useSnackbar from '@/composables/useSnackbar'

export default defineComponent({
  name: 'JourneyEntry',
  emits: ['showTickets'],
  components: {
    PeopleIcon,
    Spinner
  },
  props: {
    journey: {
      type: Object as PropType<TravelAccountJourneySet>,
      required: true
    }
  },
  setup (props) {
    const { setSnackbar } = useSnackbar()
    const loadingBookAgain = ref(false)
    const Outbound = toRef(props.journey, 'Outbound')
    const Inbound = toRef(props.journey, 'Inbound')
    const isUpcoming = computed(() => {
      const now = Date.now()
      const departure = Date.parse(
        Inbound.value?.ArrivalTime || Outbound.value?.ArrivalTime || ''
      )
      return departure > now
    })

    const passengers = computed(() =>
      Outbound.value?.PassengersNumber || Inbound.value?.PassengersNumber
    )

    // eslint-disable-next-line complexity
    const dates = computed(() => {
      if (Inbound.value && Outbound.value) {
        if (formatDate(Outbound.value.DepartureTime) === formatDate(Inbound.value.DepartureTime)) {
          return formatDate(Inbound.value.DepartureTime)
        }
        return `${formatDate(Outbound.value.DepartureTime)} - ${
          formatDate(Inbound.value.DepartureTime)}`
      }
      return formatDate(Inbound.value?.DepartureTime || Outbound.value?.DepartureTime)
    })

    const destinations = computed(() => {
      if (Outbound.value) {
        return `${Outbound.value.DepartureCityName} - ${Outbound.value.DestinationCityName}`
      }
      if (Inbound.value) {
        return `${Inbound.value.DepartureCityName} - ${Inbound.value.DestinationCityName}`
      }
      return ''
    })

    const bookAgain = () => {
      loadingBookAgain.value = true
      useBookAgain(props.journey.ShoppingBasketId)
        .catch(e => {
          setSnackbar(e.message)
          loadingBookAgain.value = false
        })
    }

    const isRefundable = computed(() => {
      if (Outbound.value) {
        return Outbound.value.IsRefundable
      }
      if (Inbound.value) {
        return Inbound.value.IsRefundable
      }
      return ''
    })

    return {
      isRefundable,
      loadingBookAgain,
      formatDate,
      isUpcoming,
      passengers,
      dates,
      destinations,
      bookAgain
    }
  }
})
