
import { defineComponent, reactive, ref } from 'vue'
import useSnackbar from '@/composables/useSnackbar'
import { maxLength, minLength, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { AxiosError } from 'axios'
import { saveCardToBuyerAccount } from '@/expressway-api/wallet'
import Input from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import MonthYearSelector from '@/components/MonthYearSelector.vue'
import CardTokenizationForm from '@/components/worldpay/CardTokenizationForm.vue'

const cardFormRules = {
  CardHolderName: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(40)
  }
}
export default defineComponent({
  emits: ['cardAdded'],
  components: {
    'v-input': Input,
    Spinner,
    MonthYearSelector,
    CardTokenizationForm
  },
  setup (_, { emit }) {
    const newCard = reactive({
      CardHolderName: ''
    })

    const loading = ref(false)
    const { setSnackbar } = useSnackbar()
    const validation = useVuelidate(cardFormRules, newCard)
    const cardTokenizationFormRef = ref()
    const saveCardCompletedFuse = ref(false)

    const startSaveCard = async () => {
      if (await validation.value.$validate()) {
        loading.value = true

        cardTokenizationFormRef.value.tokenizeCard()
      }
    }

    const resetForm = async () => {
      setSnackbar('Please check the card details below are correct')
      loading.value = false
    }

    // eslint-disable-next-line
    const finishSaveCard = async (cardToken: string, _cvcSession: string) => {
      if (saveCardCompletedFuse.value === true) return
      saveCardCompletedFuse.value = true

      if (cardToken === '') return

      saveCardToBuyerAccount({
        CardHolderName: newCard.CardHolderName,
        CardSessionHref: cardToken
      }).then(() => {
        emit('cardAdded')
        newCard.CardHolderName = ''
        validation.value.$reset()
        setSnackbar('Your details have been updated.', 'success')
      }).catch((e: AxiosError) => {
        setSnackbar(e.message)
        loading.value = false
      })
    }
    return {
      loading,
      newCard,
      startSaveCard,
      finishSaveCard,
      resetForm,
      v$: validation,
      cardTokenizationFormRef
    }
  }
})
