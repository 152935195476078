
import Spinner from '@/components/Spinner.vue'
import { defineComponent, reactive } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import { getPaymentMethods } from '@/expressway-api/wallet'
import { AxiosError } from 'axios'
import Card from '@/components/vectors/Card.vue'
import useSnackbar from '@/composables/useSnackbar'
import ShowPaymentInfo from '@/components/PaymentMethods/ShowPaymentInfo.vue'
import NewCardForm from '@/components/PaymentMethods/NewCardForm.vue'

export default defineComponent({
  name: 'PaymentDetails',
  components: {
    PageHeader,
    Card,
    ShowPaymentInfo,
    NewCardForm,
    Spinner
  },
  setup () {
    const state = reactive({
      addingCard: false,
      card: null,
      loading: true
    })
    const { setSnackbar } = useSnackbar()
    const getCurrentPaymentMethod = () => {
      state.loading = true
      getPaymentMethods().then(res => {
        state.card = res.data.PaymentMethod
        state.loading = false
      }).catch((e: AxiosError) => {
        setSnackbar(e.message)
        state.loading = false
      })
    }
    getCurrentPaymentMethod()
    const deletedCard = () => {
      state.card = null
    }
    const cardAdded = () => {
      state.loading = true
      state.addingCard = false
      getCurrentPaymentMethod()
    }
    return {
      state,
      deletedCard,
      cardAdded,
      getCurrentPaymentMethod
    }
  }
})
