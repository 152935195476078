
import { formatDate } from '@/helpers'
import { computed, defineComponent, PropType, ref } from 'vue'
import PeopleIcon from '@/components/vectors/PeopleIcon.vue'
import { TravelPass } from '@/models/TravelPass'
import Spinner from '@/components/Spinner.vue'

export default defineComponent({
  name: 'TravelPassEntry',
  emits: ['showTravelPass', 'showStops'],
  components: {
    PeopleIcon,
    Spinner
  },
  props: {
    travelPass: {
      type: Object as PropType<TravelPass>,
      required: true
    }
  },
  setup (props) {
    const loadingBookAgain = ref(false)
    const isValid = computed(() =>
      Date.parse(props.travelPass.ValidUntil) >= Date.now() &&
      props.travelPass.NumberOfUsagesLeft > 0
    )
    const buyAgainRoute = {
      name: 'Buy TravelPass',
      query: {
        originId: props.travelPass.ValidityZonePair.FirstZoneBusStops[0].BusStopId,
        destinationId: props.travelPass.ValidityZonePair.SecondZoneBusStops[0].BusStopId,
        passengers: `${props.travelPass.BonusSchemeGroupName.toLowerCase()},1`
      }
    }

    return {
      loadingBookAgain,
      formatDate,
      isValid,
      buyAgainRoute
    }
  }
})
