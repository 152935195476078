
import { computed, defineComponent, ref } from 'vue'
import useSwrv from 'swrv'
import { getTravelAccountTravelPasses } from '@/expressway-api/travelAccountTravelPasses.api'
import { TravelPass } from '@/models/TravelPass'
import { TabOption } from '@/models/System'
import PageHeader from '@/components/PageHeader.vue'
import Tabs from '@/components/Tabs.vue'
import GenericError from '@/components/GenericError.vue'
import ArrowDown from '@/components/vectors/ArrowDown.vue'
import Spinner from '@/components/Spinner.vue'
import TravelPassEntryList from '@/components/TravelPassEntryList.vue'
import useSessions from '@/composables/useSessions'

const sortTravelPasses = (travelPasses: TravelPass[], direction: string) =>
  travelPasses.sort((a: TravelPass, b: TravelPass) =>
    direction === 'asc'
      ? Date.parse(b.ValidUntil) - Date.parse(a.ValidUntil)
      : Date.parse(a.ValidUntil) - Date.parse(b.ValidUntil)
  )

const tabsOptions: TabOption[] = [
  {
    value: 'valid',
    label: 'VALID'
  },
  {
    value: 'usedAndExpired',
    label: 'USED AND EXPIRED'
  }
]

export default defineComponent({
  name: 'MyTravelPasses',
  components: {
    PageHeader,
    Tabs,
    TravelPassEntryList,
    ArrowDown,
    Spinner,
    GenericError
  },
  setup () {
    const { userSession } = useSessions()
    const { data: travelPasses, error } = useSwrv(
      () => `${userSession.value?.SessionId}-/travel-accounts/travel-passes`,
      () =>
        getTravelAccountTravelPasses()
    )
    const tabSelected = ref('valid')
    const pageQuantity = 5
    const indexActiveTravelPasses = ref(pageQuantity)
    const validTravelPasses = computed(() => sortTravelPasses(
      travelPasses.value?.filter(travelPass =>
        Date.parse(travelPass.ValidUntil) >= Date.now() && travelPass.NumberOfUsagesLeft > 0
      ) || [],
      'desc'
    ))
    const expiredTravelPasses = computed(() => sortTravelPasses(
      travelPasses.value?.filter(travelPass =>
        Date.parse(travelPass.ValidUntil) < Date.now() || travelPass.NumberOfUsagesLeft === 0
      ) || [],
      'asc'
    ))
    const filteredTravelPasses = computed(() =>
      tabSelected.value === 'valid' ? validTravelPasses.value : expiredTravelPasses.value)

    const paginatedTravelPasses = computed(() =>
      filteredTravelPasses.value.slice(0, indexActiveTravelPasses.value))

    const nextActiveTravelPasses = () => {
      const offset = filteredTravelPasses.value.length - indexActiveTravelPasses.value
      const sumToIndex = offset >= pageQuantity ? pageQuantity : offset
      indexActiveTravelPasses.value = indexActiveTravelPasses.value + sumToIndex
    }

    const updateTabSelected = (newTab: string) => {
      tabSelected.value = newTab
      indexActiveTravelPasses.value = pageQuantity
    }

    const loading = computed(() => {
      if (error.value) {
        return false
      }
      if (travelPasses.value && !error.value) {
        return false
      }
      return true
    })

    return {
      loading,
      tabsOptions,
      updateTabSelected,
      tabSelected,
      filteredTravelPasses,
      paginatedTravelPasses,
      nextActiveTravelPasses,
      error,
      travelPasses,
      indexActiveTravelPasses
    }
  }
})
