<template>
  <svg
    width="76"
    height="60"
    viewBox="0 0 76 60"
    fill="#FBCAD4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M68 0H8C3.8375 0 0.5375 3.3375 0.5375 7.5L0.5 52.5C0.5 56.6625 3.8375 60 8 60H68C72.1625 60 75.5 56.6625 75.5 52.5V7.5C75.5 3.3375 72.1625 0 68 0ZM68 52.5H8V30H68V52.5ZM8 15H68V7.5H8V15Z"
    />
  </svg>
</template>
