<template>
<svg
  width="63"
  height="63"
  viewBox="0 0 63 63"
  fill="#FBCAD4"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M60.7778 0L60.2267 0.105L41.8333 7.35L21.1667 0L1.74 6.65C1.01667 6.895 0.5 7.525 0.5 8.33V61.25C0.5 62.23 1.25778 63 2.22222 63L2.77333 62.895L21.1667 55.65L41.8333 63L61.26 56.35C61.9833 56.105 62.5 55.475 62.5 54.67V1.75C62.5 0.77 61.7422 0 60.7778 0ZM41.8333 56L21.1667 48.615V7L41.8333 14.385V56Z"
    fill-rule="evenodd"
  />
</svg>
</template>
