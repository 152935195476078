
import router from '@/router'
import { defineComponent, ref } from 'vue'
import Spinner from '@/components/Spinner.vue'
import GenericError from '@/components/GenericError.vue'
import PageHeader from '@/components/PageHeader.vue'
import { getPage, Page } from '@/kentico-api/submenus.api'
import useTitle from '@/composables/useTitle'
import { AxiosResponse } from 'axios'
import TrashIcon from '@/components/vectors/TrashIcon.vue'
import { DeleteAccount as deleteAccountApi } from '@/expressway-api/auth.api'
import useSnackbar from '@/composables/useSnackbar'
import useSessions from '@/composables/useSessions'

export default defineComponent({
  components: { Spinner, GenericError, PageHeader, TrashIcon },
  setup () {
    const { setTitle } = useTitle()
    const page = ref()
    const error = ref()
    const loading = ref(false)
    const { setSnackbar, closeSnackbar } = useSnackbar()
    const { logOut } = useSessions()

    const loadPageContent = (slug: string) => {
      getPage(slug)
        .then(({ data }: AxiosResponse<Page>) => {
          if (!Object.keys(data).length) {
            error.value = true
            return
          }

          page.value = data
        })
        .catch(e => {
          error.value = e
        })
    }

    const deleteAccount = () => {
      closeSnackbar()
      loading.value = true
      deleteAccountApi()
        .then(() => {
          logOut()
          router.push({
            name: 'Home'
          }).then(() => {
            setSnackbar('Your account has been deleted')
          })
        })
        .catch(() => {
          setSnackbar('Error deleting account')
        })
        .finally(() => {
          loading.value = false
        })
    }

    loadPageContent('delete-account-disclaimer')

    setTitle('Delete account')

    return { page, error, deleteAccount, loading }
  }
})
