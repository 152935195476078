
import { defineComponent, PropType } from 'vue'
import { TravelPass } from '@/models/TravelPass'
import Modal from '@/components/Modal.vue'
import QrcodeVue from 'qrcode.vue'

export default defineComponent({
  name: 'TravelPassModal',
  emits: ['closeModal'],
  components: {
    Modal,
    QrcodeVue
  },
  props: {
    travelPass: {
      type: Object as PropType<TravelPass>,
      required: true
    }
  }
})
