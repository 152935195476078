<template>
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6367 0.4H3.76171C2.15566 0.4 0.849219 1.70644 0.849219 3.31249V13.8125C0.849219 15.4185 2.15566 16.725 3.76171 16.725H17.6367C19.2428 16.725 20.5492 15.4185 20.5492 13.8125V3.31249C20.5492 1.70644 19.2428 0.4 17.6367 0.4ZM19.2242 10.15H15.1992C14.324 10.15 13.6117 9.43777 13.6117 8.56249C13.6117 7.6872 14.3239 6.97498 15.1992 6.97498H19.2242V10.15ZM19.2242 5.64999H15.1992C13.5932 5.64999 12.2867 6.95644 12.2867 8.56249C12.2867 10.1685 13.5932 11.475 15.1992 11.475H19.2242V13.8125C19.2242 14.6877 18.512 15.4 17.6367 15.4H3.76171C2.88646 15.4 2.1742 14.6878 2.1742 13.8125V3.31249C2.1742 2.43724 2.88643 1.72498 3.76171 1.72498H17.6367C18.512 1.72498 19.2242 2.43721 19.2242 3.31249V5.64999Z"
      fill="#C2002F"
      stroke="#C2002F"
      stroke-width="0.2"
    />
  </svg>
</template>
