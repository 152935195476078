
import { defineComponent } from 'vue'
import Wallet from '@/components/vectors/Wallet.vue'
import LittlePerson from '@/components/vectors/LittlePerson.vue'
import Payment from '@/components/vectors/Payment.vue'
import TravelPass from '@/components/vectors/TravelPass.vue'
import Door from '@/components/vectors/Door.vue'
import LittleArrowForward from '@/components/vectors/LittleArrowForward.vue'
import { useRouter } from 'vue-router'
import useSnackbar from '@/composables/useSnackbar'
import useSessions from '@/composables/useSessions'

export default defineComponent({
  components: {
    Wallet,
    LittlePerson,
    Payment,
    TravelPass,
    Door,
    LittleArrowForward
  },
  setup () {
    const { setSnackbar } = useSnackbar()
    const { logOut } = useSessions()
    const router = useRouter()
    const signOut = () => {
      logOut()
      router.push({ name: 'Home' }).then(() => {
        setSnackbar('You just signed out.', 'success')
      })
    }
    return { signOut, router }
  }
})
