<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM10 4C10 2.9 9.1 2 8 2C6.9 2 6 2.9 6 4C6 5.1 6.9 6 8 6C9.1 6 10 5.1 10 4ZM14 14C13.8 13.29 10.7 12 8 12C5.31 12 2.23 13.28 2 14H14ZM0 14C0 11.34 5.33 10 8 10C10.67 10 16 11.34 16 14V16H0V14Z"
      fill="#C2002F"
    />
  </svg>
</template>
