
import { defineComponent, PropType, ref } from 'vue'
import JourneyEntry from '@/components/JourneyEntry.vue'
import RouteIcon from '@/components/vectors/RouteIcon.vue'
import {
  TravelAccountJourneySet,
  TravelAccountJourneyDetailed
} from '@/models/TravelAccountJourney'
import TicketsModal from '@/components/BookingInfo/TicketsModal.vue'
import { getBookingInfo } from '@/expressway-api/travelAccountJourneys.api'

export default defineComponent({
  name: 'JourneyEntryList',
  components: {
    JourneyEntry,
    RouteIcon,
    TicketsModal
  },
  props: {
    journeys: {
      type: Array as PropType<TravelAccountJourneySet[]>,
      required: true
    },
    type: {
      type: String as PropType<'upcoming' | 'past'>
    }
  },
  setup () {
    const showTicketsModal = ref(false)
    const loadingTickets = ref(false)
    const errorLoadingTickets = ref('')
    const ticketsJourneys = ref([] as TravelAccountJourneyDetailed[])
    const showJourneysTickets = (journeys: TravelAccountJourneySet) => {
      errorLoadingTickets.value = ''
      ticketsJourneys.value = []
      loadingTickets.value = true
      showTicketsModal.value = true
      getBookingInfo(journeys.ShoppingBasketId).then(res => {
        ticketsJourneys.value = res
      }).catch(e => {
        errorLoadingTickets.value = e.message
      }).finally(() => {
        loadingTickets.value = false
      })
    }
    return {
      showTicketsModal,
      ticketsJourneys,
      loadingTickets,
      showJourneysTickets,
      errorLoadingTickets
    }
  }
})
