
import { defineComponent, reactive } from 'vue'
import Modal from '@/components/Modal.vue'
import TrashIcon from '@/components/vectors/TrashIcon.vue'
import Spinner from '@/components/Spinner.vue'
import { deleteCardFromBuyerAccount } from '@/expressway-api/wallet'
import useSnackbar from '@/composables/useSnackbar'
import { AxiosError } from 'axios'

export default defineComponent({
  components: {
    Modal,
    TrashIcon,
    Spinner
  },
  props: {
    card: {
      required: true,
      type: Object
    }
  },
  emits: ['deletedCard'],
  setup (props, { emit }) {
    const state = reactive({
      showDeleteConfirmationModal: false,
      loading: false
    })
    const { setSnackbar } = useSnackbar()
    const deleteCard = async () => {
      state.showDeleteConfirmationModal = true
    }
    const confirmDeleteCard = async () => {
      state.showDeleteConfirmationModal = false
      state.loading = true
      deleteCardFromBuyerAccount().then(() => {
        emit('deletedCard')
        state.loading = false
        setSnackbar('Your card details have been deleted.', 'success')
      }).catch((e: AxiosError) => {
        setSnackbar(e.message)
        state.loading = false
      })
    }
    return { props, state, deleteCard, confirmDeleteCard }
  }
})
