
import { computed, defineComponent, ref } from 'vue'
import useSwrv from 'swrv'
import { getTravelAccountJourneys } from '@/expressway-api/travelAccountJourneys.api'
import { TravelAccountJourneySet, TravelAccountJourney } from '@/models/TravelAccountJourney'
import { TabOption } from '@/models/System'
import JourneyEntryList from '@/components/JourneyEntryList.vue'
import PageHeader from '@/components/PageHeader.vue'
import Tabs from '@/components/Tabs.vue'
import GenericError from '@/components/GenericError.vue'
import ArrowDown from '@/components/vectors/ArrowDown.vue'
import Spinner from '@/components/Spinner.vue'
import useSessions from '@/composables/useSessions'

const isUpcoming = (date: string) => Date.parse(date) > Date.now()

const computeJourneySetDate = ({ Outbound, Inbound }: TravelAccountJourneySet) => {
  if (Outbound && isUpcoming(Outbound.DepartureTime)) {
    return Outbound.DepartureTime
  }
  return ((Inbound || Outbound) as TravelAccountJourney).DepartureTime
}

const sortJourneys = (journeys: TravelAccountJourneySet[], direction: string) =>
  journeys.sort((a: TravelAccountJourneySet, b: TravelAccountJourneySet) => {
    const aDate = computeJourneySetDate(a)
    const bDate = computeJourneySetDate(b)
    return direction === 'asc'
      ? Date.parse(bDate) - Date.parse(aDate)
      : Date.parse(aDate) - Date.parse(bDate)
  })

const tabsOptions: TabOption[] = [
  {
    value: 'upcoming',
    label: 'Upcoming'
  },
  {
    value: 'past',
    label: 'Past'
  }
]
export default defineComponent({
  name: 'MyTickets',
  components: {
    PageHeader,
    Tabs,
    JourneyEntryList,
    ArrowDown,
    Spinner,
    GenericError
  },
  setup () {
    const { userSession } = useSessions()
    const { data: journeys, error } = useSwrv(
      () => `${userSession.value?.SessionId}-/travel-accounts/journeys`,
      () =>
        getTravelAccountJourneys()
    )
    const tabSelected = ref('upcoming')
    const pageQuantity = 5
    const indexActiveJourneys = ref(pageQuantity)
    const upcomingJourneys = computed(() => sortJourneys(
      journeys.value?.filter(({ Inbound, Outbound }: TravelAccountJourneySet) =>
        Date.parse(Inbound?.ArrivalTime || Outbound?.ArrivalTime || '') >= Date.now()
      ) || [],
      'desc'
    ))
    const pastJourneys = computed(() => sortJourneys(
      journeys.value?.filter(({ Inbound, Outbound }: TravelAccountJourneySet) =>
        Date.parse(Inbound?.ArrivalTime || Outbound?.ArrivalTime || '') < Date.now()
      ) || [],
      'asc'
    ))
    const filteredJourneys = computed(() =>
      tabSelected.value === 'upcoming' ? upcomingJourneys.value : pastJourneys.value
    )
    const paginatedJourneys = computed(() =>
      filteredJourneys.value.slice(0, indexActiveJourneys.value)
    )
    const nextActiveJourneys = () => {
      const offset = filteredJourneys.value.length - indexActiveJourneys.value
      const sumToIndex = offset >= pageQuantity ? pageQuantity : offset
      indexActiveJourneys.value = indexActiveJourneys.value + sumToIndex
    }
    const updateTabSelected = (newTab: string) => {
      tabSelected.value = newTab
      indexActiveJourneys.value = pageQuantity
    }
    const loading = computed(() => {
      if (error.value) {
        return false
      }
      if (journeys.value && !error.value) {
        return false
      }
      return true
    })
    return {
      loading,
      tabsOptions,
      updateTabSelected,
      tabSelected,
      filteredJourneys,
      paginatedJourneys,
      nextActiveJourneys,
      error,
      journeys,
      indexActiveJourneys
    }
  }
})
