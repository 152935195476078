
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
import { getRouteBreadCrumbs } from '@/helpers/system'

export default defineComponent({
  setup () {
    const router = useRouter()
    const breadcrumbs = computed(() => getRouteBreadCrumbs(router))
    return { router, breadcrumbs }
  }
})
