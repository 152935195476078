import { httpClient } from './httpClient'
import { TravelPass } from '@/models/TravelPass'

const travelAccountTravelPassesRoute = '/travel-accounts/travel-passes'

const getTravelAccountTravelPasses = (): Promise<TravelPass[]> =>
  httpClient
    .get<{ TravelPasses: TravelPass[] }>(travelAccountTravelPassesRoute)
    .then(response => response.data.TravelPasses)

export {
  getTravelAccountTravelPasses
}
