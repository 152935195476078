<template>
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="#FBCAD4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4688 26.875H69.5312V69.5312C69.5312 74.9678 65.1241 79.375 59.6875 79.375H20.3125C14.8759 79.375 10.4688 74.9678 10.4688 69.5312V26.875ZM30.1562 62.9688V40H23.5938V62.9688H30.1562ZM43.2812 62.9688V40H36.7188V62.9688H43.2812ZM56.4062 62.9688V40H49.8438V62.9688H56.4062ZM76.0938 13.75C77.9059 13.75 79.375 15.2191 79.375 17.0312C79.375 18.8434 77.9059 20.3125 76.0938 20.3125H3.90625C2.09407 20.3125 0.625 18.8434 0.625 17.0312C0.625 15.2191 2.09407 13.75 3.90625 13.75H23.5938V3.90625C23.5938 2.09407 25.0628 0.625 26.875 0.625H53.125C54.9372 0.625 56.4062 2.09407 56.4062 3.90625V13.75H76.0938ZM30.1562 7.1875V13.75H49.8438V7.1875H30.1562Z"
    />
  </svg>
</template>
