
import {
  defineComponent,
  PropType,
  ref,
  computed
} from 'vue'
import TravelPassEntry from '@/components/TravelPassEntry.vue'
import TravelPassIcon from '@/components/vectors/TravelPass.vue'
import { TravelPass } from '@/models/TravelPass'
import TravelPassModal from '@/components/TravelPassModal.vue'
import TravelPassStopsModal from '@/components/TravelPassStopsModal.vue'

export default defineComponent({
  name: 'TravelPassEntryList',
  components: {
    TravelPassEntry,
    TravelPassIcon,
    TravelPassModal,
    TravelPassStopsModal
  },
  props: {
    travelPasses: {
      type: Array as PropType<TravelPass[]>,
      required: true
    },
    type: {
      type: String as PropType<'valid' | 'usedAndExpired'>
    }
  },
  setup (props) {
    const showTravelPassModal = ref(false)
    const showStopsModal = ref(false)
    const selectedTravelPass = ref({})
    const currentType = computed(() => props.type === 'valid' ? 'Valid' : 'Used and Expired')

    const openTravelPassModal = (travelPass: TravelPass) => {
      selectedTravelPass.value = travelPass
      showTravelPassModal.value = true
    }

    const closeTravelPassModal = () => {
      selectedTravelPass.value = {}
      showTravelPassModal.value = false
    }

    const openStopsModal = (travelPass: TravelPass) => {
      selectedTravelPass.value = travelPass
      showStopsModal.value = true
    }

    const closeStopsModal = () => {
      selectedTravelPass.value = {}
      showStopsModal.value = false
    }

    return {
      showTravelPassModal,
      selectedTravelPass,
      currentType,
      closeTravelPassModal,
      openTravelPassModal,
      showStopsModal,
      closeStopsModal,
      openStopsModal
    }
  }
})
